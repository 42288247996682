import React, { useEffect, useState } from "react";
import { useContentful } from 'react-contentful';
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Shimmer from "../../components/shimmer/Shimmer";
import ItemGenerator from "../../config/ItemGenerator";
import "./legal.css";
import LegalItem from "./widgets/LegalItem";
import Title from "../../config/Title";

const Legal = () => {
    const { data, error, fetched, loading } = useContentful({ contentType: 'legalDocumentGroup' });
    const [legals, setLegals] = useState([]);
    const [filteredLegals, setFilteredLegals] = useState([]);

    useEffect(() => {
        if (data && data["items"].length > 0) {
            const legals = data["items"].map((item) => ({
                group: item.fields.group,
                legals: item.fields.legals.map((legal) => ({
                    header: legal.fields.header,
                    slug: legal.fields.slug,
                    categories: legal.fields.categories,
                })),
            }));

            // Sort the legals to ensure the group "GENERAL" is first
            const sortedLegals = legals.sort((a, b) => {
                if (a.group.toUpperCase() === 'GENERAL') return -1;
                if (b.group.toUpperCase() === 'GENERAL') return 1;
                return 0;
            });
            setLegals(sortedLegals)
            setFilteredLegals(sortedLegals)
        }
    }, [data])

    const search = (search = '') => {
        if (search !== '') {
            const lowerCase = search.toLowerCase();
            const filteredData = legals.map(legalGroup => {
                const filteredLegals = legalGroup.legals.filter(legal =>
                    legal.header.toLowerCase().includes(lowerCase) ||
                    legal.slug.toLowerCase().includes(lowerCase) ||
                    legal.categories.some(category => category.toLowerCase().includes(lowerCase))
                );

                // Return the legal group with only the filtered legals
                return {
                    ...legalGroup,
                    legals: filteredLegals
                };
            }).filter(legalGroup => legalGroup.legals.length > 0 || legalGroup.group.toLowerCase().includes(lowerCase));

            // Sort the legals to ensure the group "GENERAL" is first
            const sortedFilteredData = filteredData.sort((a, b) => {
                if (a.group.toUpperCase() === 'GENERAL') return -1;
                if (b.group.toUpperCase() === 'GENERAL') return 1;
                return 0;
            });

            setFilteredLegals(sortedFilteredData);
        } else {
            setFilteredLegals(legals);
        }
    };


    if (loading || !fetched || error || !data || data["items"].length === 0) {
        return (
            <div className="about-container">
                <Title title="Legal Hub" description='Serch policies and guiding rules' />
                <Header />
                <div className="legal-container">
                    <div className="legal-header">
                        <span className="legal-title">Legal Hub | Serch</span>
                        <span className="legal-subtitle">
                            Here you&apos;ll find legal information and resources for using the
                            Serch platform. We recommend going through these documents before you
                            engage in any of our platforms.
                        </span>
                    </div>
                    <div className="legal-input-container">
                        <Shimmer height={80} percentWidth="100%" />
                    </div>
                    {
                        ItemGenerator(length = 5).map((_, key) => {
                            return (
                                <div style={{ marginBottom: "10px", width: "100%", height: "80px" }} key={key}>
                                    <Shimmer key={key} height={80} percentWidth="100%" />
                                </div>
                            )
                        })
                    }
                </div>
                <Footer />
            </div>
        );
    } else {
        return (
            <div className="about-container">
                <Title title="Legal Hub" description='Serch policies and guiding rules' />
                <Header />
                <div className="legal-container">
                    <div className="legal-header">
                        <span className="legal-title">Legal Hub | Serch</span>
                        <span className="legal-subtitle">
                            Here you&apos;ll find legal information and resources for using the
                            Serch platform. We recommend going through these documents before you
                            engage in any of our platforms.
                        </span>
                    </div>
                    <div className="legal-input-container">
                        <input
                            type="text"
                            placeholder="Search for terms, categories or keywords"
                            className="legal-textinput input"
                            onChange={e => search(e.target.value)}
                        />
                    </div>
                    {
                        filteredLegals.map((legalGroup, key) => {
                            return (
                                <div className="legal-legal-group" key={key}>
                                    <span className="legal-text">{legalGroup.group}</span>
                                    {legalGroup.legals.map((legal, key) => {
                                        return (<LegalItem
                                            key={key}
                                            slug={legal.slug}
                                            title={legal.header}
                                            categories={legal.categories}
                                        />)
                                    })}
                                </div>
                            )
                        })
                    }
                </div>
                <Footer />
            </div>
        );
    }
};

export default Legal;
