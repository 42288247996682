const Assets = {
    shareExternal: require("./external/share.png"),
    fasterCapital: require("./external/FasterCapital.png"),

    android200H: require("./icons/android-200h.png"),
    android1500H: require("./icons/android-1500h.png"),
    apple200W: require("./icons/apple-200w.png"),
    apple1500H: require("./icons/apple-1500h.png"),
    menu: require("./icons/hamburger-200h.png"),
    instagram: require("./icons/instagram-200h.png"),
    linkedIn: require("./icons/linkedin-200h.png"),
    twitter: require("./icons/twitter-200h.png"),
    youtube: require("./icons/youtube.png"),

    dark200H: require("./logos/dark-200h.png"),
    light200H: require("./logos/light-200h.png"),
    light1500H: require("./logos/light-1500h.png"),
    light1500W: require("./logos/light-1500w.png"),
    rounded: require("./logos/rounded.png"),
    tagBlack200H: require("./logos/tagblack-200h.png"),
    tagWhite200H: require("./logos/tagwhite-200h.png"),

    business200H: require("./section/business-200h.png"),
    business400W: require("./section/business-400w.png"),
    carpenter200H: require("./section/carpenter-200h.png"),
    carpenter300H: require("./section/carpenter-300h.png"),
    providerApp: require("./section/providerApp.png"),
    businessApp: require("./section/businessApp.png"),
    userApp: require("./section/userApp.png"),
    electrician200W: require("./section/electrician-200w.png"),
    electrician300H: require("./section/electrician-300h.png"),
    mechanic200H: require("./section/mechanic-200h.png"),
    mechanic300H: require("./section/mechanic-300h.png"),
    notes200H: require("./section/notes-200h.png"),
    plumber200W: require("./section/plumber-200w.png"),
    plumber300H: require("./section/plumber-300h.png"),
    support: require("./section/support.png"),
    users200H: require("./section/users-200h.png"),
    users400W: require("./section/users-400w.png"),
    verified200H: require("./section/verified-200h.png"),
    verified1500W: require("./section/verified-1500w.png"),

    stepActivateAccount: require("./steppers/step - activate account.png"),
    stepAuthenticate: require("./steppers/step - authenticate.png"),
    stepConnectWithLink: require("./steppers/step - connect with link.png"),
    stepCreateAccount: require("./steppers/step - create account.png"),
    stepDiscussion: require("./steppers/step - discussion.png"),
    stepEnterLocation: require("./steppers/step - enter location.png"),
    stepFix: require("./steppers/step - fix.png"),
    stepMentor: require("./steppers/step - mentor.png"),
    stepPickServiceMode: require("./steppers/step - pick service mode.png"),
    stepPlans: require("./steppers/step - plans.png"),
    stepRate: require("./steppers/step - rate.png"),
    stepRequestSharing: require("./steppers/step - requestsharing.png"),
    stepSelectCategory: require("./steppers/step - select category.png"),
    stepShareLink: require("./steppers/step - share link.png"),
    stepSharedLink: require("./steppers/step - shared link.png"),
    stepTip2Fix: require("./steppers/step - tip2fix.png"),
    stepTrack: require("./steppers/step - track.png"),
    stepUploadDocuments: require("./steppers/step - upload documents.png"),
    stepVerificationStatus: require("./steppers/step - verification status.png"),
    stepVerification: require("./steppers/step - verification.png"),

    error: require("./external/error.gif"),
    loading: require("./external/loading.gif"),
    success: require("./external/success.gif"),
}

export default Assets;